import React from 'react';
import MyNavbar from './Navbar';
import styled, { keyframes } from 'styled-components';
import backGroundImage from '../assets/background_1.png';
import Car from './CarAnimation/Car';

const Hero = () => {
    return (
        <HeroSection aria-label="Hero Section">
            <MyNavbar />
            <Content>
                <h1>Friends Track Drop Taxi</h1>
                <Tagline>"Meter starts from your doorstep"</Tagline>
                <Quote aria-label="Quote Section">
                    <h2>No District Left Behind</h2>
                    <span>Tamil Nadu's Complete Taxi Service !!</span>
                </Quote>
                <CarContainer aria-label="Car Animation">
                    <Car alt="Car Animation" />
                </CarContainer>
            </Content>
        </HeroSection>
    );
};

export default Hero;

const carAnimation = keyframes`
    0% {
        margin-left: -110%;
    }

    100% {
        margin-left: 100%;
    }
`;

const HeroSection = styled.div`
    position: relative;
    
    width: 100%;
    height: 100vh;
    background-image: url(${backGroundImage});
    background-position: center;
    background-size: cover;
`;

const CarContainer = styled.div`
    margin-top: 22%;
    animation: ${carAnimation} 10s linear infinite; /* Adjust the animation duration as needed */

    @media screen and (max-width: 1024px) {
        display: none; /* Hide the Car element in tablet and mobile view */
    }
`;

const Content = styled.div`
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    margin-top: 8.3%; 

    h1 {
        font-size: 52px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    @media screen and (max-width: 768px) {
        h1 {
            font-size: 42px;
            line-height: 40px;
        }
    }
`;

const Tagline = styled.p`
    font-size: 24px;
    margin-bottom: 30px;
    font-style: italic;
    color: #f0bf22;
`;

const Quote = styled.div`
    border: 1px solid #fff;
    width: 80%;
    max-width: 500px;
    margin: auto;
    border-radius: 5px;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.7);
    margin-bottom: 30px;

    h1 {
        font-size: 32px;
        letter-spacing: -1px;
    }

    span {
        font-size: 28px;
        font-weight: 600;
        color: #f0bf22;
    }
`;
