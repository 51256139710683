import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';

import MUVImage from "../assets/MUV.png";
import XUVImage from "../assets/XUV.png";
import SedanImage from "../assets/sedan.png";

const OfferCard = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  const offers = [
    {
      type: "Sedan Car",
      price: "₹3550",
      details: "250 km / 12 hrs",
      image: SedanImage,
    },
    {
      type: "XUV Car",
      price: "₹4550",
      details: "250 km / 12 hrs",
      image: XUVImage,
    },
    {
      type: "MUV Car",
      price: "₹4800",
      details: "250 km / 12 hrs",
      image: MUVImage,
    },
    // Add more offers as needed
  ];

  return (
    <OfferCardContainer>
      <Slider {...settings}>
        {offers.map((offer, index) => (
          <OfferCardStyled key={index} className="slick-slide">
            <img src={offer.image} alt={offer.type} />
            <OfferTitle>{offer.type}</OfferTitle>
            <OfferDetails>{offer.price}</OfferDetails>
            <OfferDetails>{offer.details}</OfferDetails>
          </OfferCardStyled>
        ))}
      </Slider>
    </OfferCardContainer>
  );
};

export default OfferCard;

const OfferCardContainer = styled.div`
  width: 100vw;
  background-color: #F0BF22;
  margin: auto;
  padding: 50px 20px;
`;

const OfferCardStyled = styled.div`
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, background-color 0.3s ease;
  &.slick-initialized{
    width: 100vw;
  }
  &.slick-slide {
    margin: 10px !important;
  }

  img {
    width: 300px;
    height: 150px;
    transform: scaleX(-1);
    margin-left: 18%;
  }

      @media screen and (max-width: 768px) {
       img{
        margin-left: 8%;
       }
}
`;

const OfferTitle = styled.h3`
  margin: 0 0 10px;
`;

const OfferDetails = styled.p`
  margin: 5px 0;
`;