import React from 'react'
import styled from 'styled-components'
import workFlow from '../assets/workFlow.svg'
const About = () => {
    return (
        <AboutSection id='about' aria-label="About Section">
            <Left>
                <TextContainer>
                    <h1>Reliable Taxi Services <br />
                        Across Tamil Nadu</h1>
                </TextContainer>
                <div>
                    <p>Experience the best cab services in the city with Friends Track Drop Taxi. Trusted by thousands of customers in New York, we pride ourselves on providing reliable transportation solutions tailored to your needs. Our team is dedicated to ensuring your comfort and safety throughout your journey. Whether you're heading to work, exploring the city, or catching a flight, count on us for a seamless travel experience. Book your ride with us today!</p>
                </div>
            </Left>
            <Right>
                <img src={workFlow} alt="Booking Workflow Diagram" />

            </Right>
        </AboutSection>
    )
}

export default About

const AboutSection = styled.div`
height: 80vh;
width: 100vw;
padding: 40px 148px;
background-color: #f0bf22;
display: flex;
align-items: center;
justify-content: space-between;
@media screen and (max-width: 768px) {
padding: 20px;
flex-direction: column;
}
`

const Left = styled.div`
flex-basis: 55%;

p{
    text-align: justify;
}

@media screen and (max-width: 768px) {
flex-basis: 100%;
margin-top: 25%;
}
`

const Right = styled.div`
flex-basis: 30%;
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;

a{
    width: 400px;
}
@media screen and (max-width: 768px) {
    flex-basis: 100%;
a{
    width: 240px;
}
}
img{
    width: 100%;
}
 @media screen and (max-width: 1024px) {
        display: none; 
    }
`

const TextContainer = styled.div`
 text-align: left;
 color: #000;
 font-weight: 600;
 font-size: 32px;
 line-height: 40px;
 text-transform: uppercase;
 @media screen and (max-width: 768px) {
text-align: left;
font-size: 28px;
}
`