

export const TariffData = [
    {
        id: 0,
        title: "ECONOMY CLASS",
        description: "Enjoy a comfortable ride at an affordable price with our Economy Class. Perfect for budget-conscious travelers who still want quality service.",
        price: 25, // Updated price for demonstration purposes
    },
    {
        id: 1,
        title: "STANDARD CLASS",
        description: "Upgrade to our Standard Class for a balance of comfort and value. Experience premium service without breaking the bank.",
        price: 50, // Updated price for demonstration purposes
    },
    {
        id: 2,
        title: "BUSINESS CLASS",
        description: "Indulge in luxury and convenience with our Business Class. Experience VIP treatment with extra legroom, priority boarding, and more.",
        price: 100, // Updated price for demonstration purposes
    }
]


export const HighLightsData = [
    {
        id: 0,
        title: "24/7 AVAILABILITY",
        description: "Need a ride at any hour? We've got you covered. Our services are available round the clock, so you can travel with ease whenever you need to."
    },
    {
        id: 1,
        title: "EFFICIENT BOOKING",
        description: "Skip the hassle and book your ride in seconds with our user-friendly booking platform. Say goodbye to long waiting times and hello to instant confirmations."
    },
    {
        id: 2,
        title: "PROFESSIONAL DRIVERS",
        description: "Rest assured knowing that our drivers are professional, experienced, and committed to providing you with a safe and comfortable journey every time you ride with us."
    },
    {
        id: 3,
        title: "CLEAN AND WELL-MAINTAINED VEHICLES",
        description: "Step into a clean and well-maintained vehicle every time you ride with us. Your comfort and satisfaction are our top priorities, so we ensure our fleet is always in top condition."
    }
]


export const TestimonialData = [
    {
        id: 0,
        name: "HOME PICKUP",
        descripiton: "Taxi is my go-to for reliable rides anytime, anywhere. Their drivers are courteous and punctual, making every trip a breeze!"
    },
    {
        id: 1,
        name: "FAST BOOKING",
        descripiton: "Taxi is top-notch! Clean cars, friendly drivers, and a strong focus on safety. I wouldn't ride with anyone else!"
    },
]
