import jsPDF from 'jspdf';
import 'jspdf-autotable';
import React, { useEffect } from 'react';
import { Bars } from 'react-loader-spinner';
import styled from 'styled-components';
import TickGif from "../../assets/Tick.gif";


const Summary = ({ uniqueID, summaryData, handleBookTaxi, handlePreviousSummary, isLoading, isBookingConfirmed, setBookingConfirmed, transactionId   }) => {

  useEffect(() => {
    if (isBookingConfirmed) {
      setTimeout(() => {
        setBookingConfirmed(false);
      }, 4000);
    }
  }, [isBookingConfirmed, setBookingConfirmed]);



  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Booking Summary", 10, 10);
    const table = document.getElementById("summary-table");
    doc.autoTable({ html: table });
    doc.save(`CabBooking_Invoice.pdf`);
  };

  const handleConfirmBooking = () => {
    handleBookTaxi();
  };

  return (
    <SummaryContainer>
      <Header>
        <h1><span>BOOKING</span> SUMMARY</h1>
      </Header>
      <SummaryTable id='summary-table'>
        <tbody>
          <SectionHeader>
            <th colSpan="2">TRIP DETAILS</th>
          </SectionHeader>
          <TableRow>
            <DataLabel>Name:</DataLabel>
            <DataValue>{summaryData.name}</DataValue>
          </TableRow>
          <TableRow>
            <DataLabel>transactionId  :</DataLabel>
            <DataValue>{summaryData.transactionId }</DataValue>
          </TableRow>
          <TableRow>
            <DataLabel>Start:</DataLabel>
            <DataValue>{summaryData.start}</DataValue>
          </TableRow>
          <TableRow>
            <DataLabel>End:</DataLabel>
            <DataValue>{summaryData.end}</DataValue>
          </TableRow>
          <SectionHeader>
            <th colSpan="2">ADDRESS DETAILS</th>
          </SectionHeader>
          <TableRow>
            <DataLabel>Selected Car:</DataLabel>
            <DataValue>{summaryData.selectedCar}</DataValue>
          </TableRow>
          <TableRow>
            <DataLabel>Trip Type:</DataLabel>
            <DataValue>{summaryData.selectedTripType}</DataValue>
          </TableRow>
          <SectionHeader>
            <th colSpan="2">CUSTOMER DETAILS</th>
          </SectionHeader>
          <TableRow>
            <DataLabel>Price:</DataLabel>
            <DataValue>₹{summaryData.price}</DataValue>
          </TableRow>
          <TableRow>
            <DataLabel>Phone Number:</DataLabel>
            <DataValue>{summaryData.phoneNumber}</DataValue>
          </TableRow>
          <TableRow>
            <DataLabel>Pick Date:</DataLabel>
            <DataValue>{summaryData.pickDate}</DataValue>
          </TableRow>
          <TableRow>
            <DataLabel>Pick Time:</DataLabel>
            <DataValue>{summaryData.pickTime}</DataValue>
          </TableRow>
          <TableRow>
            <DataLabel>Drop Date:</DataLabel>
            <DataValue>{summaryData.dropDate}</DataValue>
          </TableRow>
          <TableRow>
            <DataLabel>Drop Time:</DataLabel>
            <DataValue>{summaryData.dropTime}</DataValue>
          </TableRow>
          <TableRow>
            <DataLabel>Adult Count:</DataLabel>
            <DataValue>{summaryData.adultCount}</DataValue>
          </TableRow>
          <TableRow>
            <DataLabel>Child Count:</DataLabel>
            <DataValue>{summaryData.childCount}</DataValue>
          </TableRow>
          <TableRow>
            <DataLabel>Luggage Count:</DataLabel>
            <DataValue>{summaryData.luggageCount}</DataValue>
          </TableRow>
          <TableRow>
            <DataLabel>Pick Up Address:</DataLabel>
            <DataValue>{summaryData.pickUpAddress}</DataValue>
          </TableRow>
          <TableRow>
            <DataLabel>Drop Address:</DataLabel>
            <DataValue>{summaryData.dropAddress}</DataValue>
          </TableRow>
          <SectionHeader>
            <th colSpan="2">TERMS & CONDITIONS</th>
          </SectionHeader>
          <TableRow>
            <DataLabel>Terms Accepted:</DataLabel>
            <DataValue>{summaryData.termsAccepted ? 'Yes' : 'No'}</DataValue>
          </TableRow>
          <TableRow>
            <DataValue colSpan="2">
              Only 1 (Pickup & drop) points are allowed. More than that charges will be applicable. (Optional)
            </DataValue>
          </TableRow>
          <TableRow>
            <DataValue colSpan="2">
              Luggage allowed is 30 kg for sedan and 60 kg for MUV. If the limit is exceeded, an additional charge of Rs.300 will apply under the driver's guidance. Only 1 (Pickup & drop) points are allowed. More than that charges will be applicable. (Optional) Toll fees, Inter-State Permit charges (if any) are extra.
            </DataValue>
          </TableRow>
          <TableRow>
            <DataValue colSpan="2">
              <b>[Note: Kindly verify the bill amount on our app or booking link, and then you can settle the trip amount with the driver. If you need any clarification or assistance regarding the bill amount, please contact us at 9488 25 7000 (available 24/7).</b>
            </DataValue>
          </TableRow>
        </tbody>
      </SummaryTable>
      <ButtonGroup>
        <Button onClick={handlePreviousSummary}>Previous</Button>
        <Button onClick={downloadPDF}>Download as PDF</Button>
        <Button onClick={handleConfirmBooking} className='green'>Book Now</Button>
      </ButtonGroup>
      {isLoading && (
        <LoadingOverlay>
          <Bars type="Oval" color="#ffffff" height={50} width={50} />
          <LoadingText>Loading...</LoadingText>
        </LoadingOverlay>
      )}
      {isBookingConfirmed && <ConfirmationMessage>Booking Confirmed! <img src={TickGif} alt='TEST' /></ConfirmationMessage>}
    </SummaryContainer>
  );
};

export default Summary;

const Button = styled.button`
padding: 8px 20px;
background-color: #000;
color: #fff;

.green{
  background-color: green !important;
}
`

const ButtonGroup = styled.div`
  margin-top: 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
   @media screen and (max-width: 768px) {
       flex-wrap: wrap;
       justify-content: center;
       gap: 10px;
    }
`;


const SummaryContainer = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 20px 148px;
  overflow-y: auto;
  
  @media screen and (max-width: 768px) {
    padding: 20px;
  }
`;

const Header = styled.div`
  padding: 12px;
  background-color: #000;
  color: #fff;
  text-align: center;

  h1 {
    font-size: 26px;
    font-weight: 700;
    margin: 0;

    span {
      color: #F0BF22;
    }
  }
`;

const SummaryTable = styled.table`
  width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
`;

const SectionHeader = styled.tr`
  background-color: #F0BF22;
  font-weight: bold;
  text-align: center;

  th {
    padding: 10px;
    border: 1px solid #000;
  }
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ccc;
`;

const DataLabel = styled.td`
  padding: 10px;
  font-weight: bold;
  border-right: 1px solid #ccc;
`;

const DataValue = styled.td`
  padding: 10px;
  border-right: 1px solid #ccc;
`;

const ConfirmationMessage = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #000;
  color: #fff;
  padding: 20px;
  border-radius: 5px;
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  img{
    width: 50px;
    height: 30px;
  }
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingText = styled.p`
  margin-top: 10px;
  color: #ffffff;
`;
