import React from 'react';
import { FaFacebook, FaInstagram, FaMapMarkerAlt, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import styled from 'styled-components';
import backgroundImage4 from '../assets/background_4.png';

const Footer = () => {
    return (
        <FooterSection>
            <QuickLinks>
                <BranchTitle>Quick Links</BranchTitle>
                <NavLink href="#home">Home</NavLink>
                <NavLink href="#about">About</NavLink>
                <NavLink href="#tariff">Tariff</NavLink>
                <NavLink href="#contact">Contact Us</NavLink>
            </QuickLinks>
            <Address>
                <p>
                    K. Nagarajan <br />
                    31/13, N G G O Colony Back Side,<br />
                    Nady Amman Kovil Nagar,<br />
                    Pattukkottai<br />
                    Thanjavur Dt
                </p>
            </Address>
            <Branches>
                <BranchTitle>Branches</BranchTitle>
                <BranchName><LocationIcon /><span>Chennai</span></BranchName>
                <BranchName><LocationIcon /><span>Salem</span></BranchName>
                <BranchName><LocationIcon /><span>Trichy</span></BranchName>
                <BranchName><LocationIcon /><span>Thanjavur</span></BranchName>
            </Branches>
            <Center>
                <SocialLink href="#"><FaFacebook /></SocialLink>
                <SocialLink href="#"><FaTwitter /></SocialLink>
                <SocialLink href="#"><FaInstagram /></SocialLink>
            </Center>
            <Contact>
                <PhoneNumber>04373-242700</PhoneNumber>
                <div >
                    <IconLink href="https://wa.me/919488257000"><FaWhatsapp />
                        <PhoneNumber>9488257000</PhoneNumber>
                    </IconLink>
                </div>
                <div>
                    <IconLink href="tel:9362257000"><FaWhatsapp />
                        <PhoneNumber>9362257000</PhoneNumber>
                    </IconLink>
                </div>
            </Contact>
            <Credit>
                <a href="https://sktsoftware.in/" target="_blank" rel="noreferrer"> website design by skt software</a>
            </Credit>
        </FooterSection>
    );
};

export default Footer;

const FooterSection = styled.div`
    position: relative;
    padding: 40px 140px; /* Adjust padding for mobile responsiveness */
    width: 100%;
    height: 100%;
    background-image: url(${backgroundImage4});
    background-position: center;
    background-size: cover;
    color: #fff;
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-between; /* Updated */
    align-items: center;

    @media screen and (max-width: 768px) {
        padding: 20px;
    }

`;

const QuickLinks = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    flex-wrap: wrap;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
        justify-content: center;
        flex-basis: 40%;
    }
`;

const NavLink = styled.a`
    color: #fff;
    text-decoration: none;
    margin-right: 20px;

    &:last-child {
        margin-right: 0;
    }

    @media screen and (max-width: 768px) {
        margin-right: 10px;
        margin-bottom: 10px;
    }

    &:hover {
        color: #f0bf22; /* Hover color */
    }
`;

const Address = styled.div`
    margin-right: 20px;
    cursor: pointer;

        &:hover {
        color: #f0bf22; /* Hover color */
    }
    p{
        text-transform: capitalize !important;
    }
    @media screen and (max-width: 768px) {
        /* margin-right: 0;
        margin-bottom: 20px; */
        flex-basis: 40%;
    }
`;

const Branches = styled.div`

    margin-right: 20px;

    @media screen and (max-width: 768px) {
        /* margin-right: 0;
        margin-bottom: 20px; */
        flex-basis: 40%;
    }
`;

const BranchTitle = styled.h4`
    color: #f0bf22;
    margin-bottom: 10px;
`;

const BranchName = styled.p`
    color: #fff;
    margin: 5px 0;
    display: flex;
    align-items: center;

    &:hover {
        color: #f0bf22; /* Hover color */
    }
`;

const Center = styled.div`

    display: flex;
    justify-content: center;

    @media screen and (max-width: 768px) {
        /* margin-bottom: 20px; */
        flex-basis: 40%;
    }
`;

const SocialLink = styled.a`
    color: #fff;
    text-decoration: none;
    margin: 0 10px;
    font-size: 24px;

    @media screen and (max-width:768px){
        flex-basis: 40%;
    }
    &:hover {
        color: #f0bf22; /* Hover color */
    }
`;

const Contact = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
`;

const PhoneNumber = styled.span`
    margin-right: 10px;
            &:hover {
        color: #f0bf22; /* Hover color */
    }
    cursor: pointer;
`;

const IconLink = styled.a`
    color: #fff;
    text-decoration: none;
    margin-right: 10px;
`;

const Credit = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 5%;
    a{
        color: #fff;
        text-decoration: none;
    }
`;

const LocationIcon = styled(FaMapMarkerAlt)`
    color: #fff;
    margin-right: 5px;
`;
