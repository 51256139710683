import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Summary from './FormComponents/Summary';
import Form2 from './FormComponents/Form2';
import Form3 from './FormComponents/Form3'; // Import Form3
import Gmap from '../components/FormComponents/GMap';

const TaxiBookingForm = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [selectedCar, setSelectedCar] = useState('');
  const [showForm1, setShowForm1] = useState(true);
  const [showForm2, setShowForm2] = useState(false);
  const [showForm3, setShowForm3] = useState(false); // State for Form3
  const [phoneNumber, setPhoneNumber] = useState('');
  const [pickDate, setPickDate] = useState('');
  const [pickTime, setPickTime] = useState('');
  const [adultCount, setAdultCount] = useState(0);
  const [childCount, setChildCount] = useState(0);
  const [luggageCount, setLuggageCount] = useState(0);
  const [pickUpAddress, setPickUpAddress] = useState('');
  const [dropAddress, setDropAddress] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [summaryData, setSummaryData] = useState(null);
  const [summaryVisible, setSummaryVisible] = useState(false);
  const [uniqueID, setUniqueID] = useState('');
  const [selectedTripType, setSelectedTripType] = useState('')
  const [dropDate, setDropDate] = useState('');
  const [dropTime, setDropTime] = useState('');
  const [price, setPrice] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isBookingConfirmed, setBookingConfirmed] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const handleBookTaxi = () => {

    setIsLoading(true)
    const generatedID = `${name}${uuidv4().substr(0, 8)}`;
    setUniqueID(generatedID);

    const bookingData = {
      id: generatedID, name, start, email, end, selectedCar, dropDate, price, dropTime, phoneNumber, pickDate, pickTime, adultCount, childCount, luggageCount, pickUpAddress, dropAddress, termsAccepted, transactionId
    };
    axios.post('https://taxi-booking-hjrt.onrender.com/send-email', bookingData)
      .then(response => {
        setIsLoading(false)
        setBookingConfirmed(true)
        console.log(response.data);
      })
      .catch(error => {
        setIsLoading(false)
        console.error('Error sending email:', error);
      });

  };

  const handleForm1Submit = useCallback((e) => {
    e.preventDefault();
    setShowForm1(false);
    setShowForm2(true);
  }, []);

  useEffect(() => {
    const storedData = sessionStorage.getItem('gmapInputValues');
    if (storedData) {
      const price = sessionStorage.getItem('price');
      const parsedData = JSON.parse(storedData);
      setStart(parsedData.origin);
      setEnd(parsedData.destination);
      setSelectedTripType(parsedData.selectedTripType);
      setSelectedCar(parsedData.selectedCar);
      setPrice(price)
    }
  }, [handleForm1Submit]);

  const handleForm2Submit = (e) => {
    e.preventDefault();
    setShowForm2(false);
    setShowForm3(true); // Show Form3
  };

  const handleForm3Submit = (e) => {
    e.preventDefault();
    setSummaryData({
      uniqueID,
      name,
      email,
      start,
      end,
      selectedCar,
      selectedTripType,
      price,
      phoneNumber,
      pickDate,
      pickTime,
      dropDate,
      dropTime,
      adultCount,
      childCount,
      luggageCount,
      pickUpAddress,
      dropAddress,
      termsAccepted,
      transactionId
    });
    setShowForm3(false);
    setSummaryVisible(true);
  };

  const handlePrevious2 = (e) => {
    e.preventDefault();
    setShowForm1(true);
    setShowForm2(false);
  };

  const handlePrevious3 = (e) => {
    e.preventDefault();
    setShowForm2(true);
    setSummaryVisible(false)
    setShowForm3(false);
  };

  const handlePreviousSummary = (e) => {
    e.preventDefault();
    setShowForm3(true);
    setSummaryVisible(false);
  };

  return (
    <div id='bookNow'>
      {showForm1 && (
        <Gmap handleForm1Submit={handleForm1Submit} />
      )}
      {showForm2 && (
        <Form2
          name={name}
          setName={setName}
          email={email}
          setEmail={setEmail}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          pickDate={pickDate}
          setPickDate={setPickDate}
          pickTime={pickTime}
          setPickTime={setPickTime}
          dropDate={dropDate}
          setDropDate={setDropDate}
          dropTime={dropTime}
          setDropTime={setDropTime}
          adultCount={adultCount}
          setAdultCount={setAdultCount}
          childCount={childCount}
          setChildCount={setChildCount}
          luggageCount={luggageCount}
          setLuggageCount={setLuggageCount}
          pickUpAddress={pickUpAddress}
          setPickUpAddress={setPickUpAddress}
          dropAddress={dropAddress}
          setDropAddress={setDropAddress}
          termsAccepted={termsAccepted}
          setTermsAccepted={setTermsAccepted}
          handlePrevious2={handlePrevious2}
          handleForm2Submit={handleForm2Submit}
        />
      )}
      {showForm3 && (
        <Form3
          handlePrevious3={handlePrevious3}
          handleForm3Submit={handleForm3Submit}
          transactionId={transactionId}
          setTransactionId={setTransactionId}
        />
      )}
      {summaryVisible && (
        <Summary
          uniqueID={uniqueID}
          summaryData={summaryData}
          handlePreviousSummary={handlePreviousSummary}
          handleBookTaxi={handleBookTaxi}
          isLoading={isLoading}
          isBookingConfirmed={isBookingConfirmed}
          setBookingConfirmed={setBookingConfirmed}
        />
      )}
    </div>
  )
}

export default TaxiBookingForm;
