import React from 'react';
import styled, { keyframes } from 'styled-components';
import CarImage from "../../assets/car.png";
import Tire from "../../assets/tire.png";

const Car = () => {
    return (
        <Box aria-label="Car Animation">
            <CarContainer >
                <CarImg src={CarImage} alt="Car" />
            </CarContainer>
            <WheelContainer>
                <WheelImg src={Tire} alt="Front Wheel" className="frontwheel" />
                <WheelImg src={Tire} alt="Front Wheel" className="frontwheel2" />
                <WheelImg src={Tire} alt="Back Wheel" className="backwheel" />
            </WheelContainer>
        </Box>
    );
};

export default Car;

const carAnimation = keyframes`
    0% {
        transform: translateY(-4px);
    }

    50% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(-4px);
    }
`;

const wheelAnimation = keyframes`
    100% {
        transform: rotate(360deg);
    }
`;

const Box = styled.div`
position: relative;
`;

const CarContainer = styled.div`
    position: absolute;
    width: 400px;
    left: 50%;
    bottom: 100px;
    transform: translateX(-50%);
    z-index: 2;
    animation: ${carAnimation} 1s linear infinite;
`;

const CarImg = styled.img`
    width: 100%;
`;

const WheelContainer = styled.div`
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      bottom: 100px;
      z-index: 2;
    .frontwheel {
        position: absolute;
        left: 315px;
    }
    .frontwheel2{
        position: absolute;
        left: 70px;
    }
    .backwheel{
        opacity: 0;
    }
    `;

const WheelImg = styled.img`
width: 72px;
height: 72px;
animation: ${wheelAnimation} 1s linear infinite;
`;
