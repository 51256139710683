import {
  Autocomplete,
  DirectionsRenderer,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import { useRef, useState } from 'react';
import styled from 'styled-components';

const center = { lat: 11.1271, lng: 78.6569 };
function Gmap({ handleForm1Submit }) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyCXZgkqkLXEn2fD37P4Er67Wyq00Zol4yA',
    libraries: ['places'],
  });

  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
  const [map, setMap] = useState(null);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState('');
  const [duration, setDuration] = useState('');
  const [selectedCar, setSelectedCar] = useState('');
  const [selectedTripType, setSelectedTripType] = useState('Oneway');
  const [price, setPrice] = useState(null);
  const [betta, setBetta] = useState(0)


  const originRef = useRef(null);
  const destinationRef = useRef(null);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  async function calculateRoute() {
    if (!originRef.current.value || !destinationRef.current.value) {
      return;
    }

    const directionsService = new window.google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: originRef.current.value,
      destination: destinationRef.current.value,
      travelMode: window.google.maps.TravelMode.DRIVING,
    });

    if (selectedTripType === 'Oneway' && results.routes[0].legs[0].distance.value >= 130000) {
      setIsNextButtonEnabled(true);
    } else if (selectedTripType === 'Twoway' && results.routes[0].legs[0].distance.value >= 250000) {
      setIsNextButtonEnabled(true);
    } else {
      setIsNextButtonEnabled(false);
    }

    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance.text);
    setDuration(results.routes[0].legs[0].duration.text);
    sessionStorage.setItem('distance', results.routes[0].legs[0].distance.text);
    sessionStorage.setItem('duration', results.routes[0].legs[0].duration.text);
    // Calculate price based on distance, selected car, and trip type
    const calculatedPrice = Math.ceil(calculatePrice(results.routes[0].legs[0].distance.value / 1000));
    setPrice(calculatedPrice);
    sessionStorage.setItem('price', calculatedPrice);
  }

  const calculatePrice = (distanceInKm) => {
    let basePrice = 0;
    let driverBetta = 0;
    let totalDistance = selectedTripType === 'Twoway' ? distanceInKm * 2 : distanceInKm;

    // Determine the base price based on the selected car and trip type
    switch (selectedCar) {
      case 'Sedan':
        basePrice = selectedTripType === 'Oneway' ? 14 : 13;
        break;
      case 'XUV':
        basePrice = selectedTripType === 'Oneway' ? 18 : 17;
        break;
      case 'Muv':
        basePrice = selectedTripType === 'Oneway' ? 19 : 18;
        break;
      default:
        break;
    }

    // Add additional driver betta based on the selected car
    switch (selectedCar) {
      case 'Sedan':
        driverBetta = 300;
        break;
      case 'XUV':
      case 'Muv':
        driverBetta = 300;
        break;
      default:
        break;
    }

    setBetta(driverBetta);
    // Calculate total prize
    const totalPrize = (basePrice * totalDistance) + driverBetta;
    const inputValues = {
      selectedCar,
      selectedTripType,
      origin: originRef.current.value,
      destination: destinationRef.current.value,
    };
    sessionStorage.setItem('gmapInputValues', JSON.stringify(inputValues));
    return totalPrize;
  };

  const handleCarSelect = (car) => {
    setSelectedCar(car);
  };

  const handleTripTypeSelect = (type) => {
    setSelectedTripType(type);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const inputValues = {
      selectedCar,
      selectedTripType,
      origin: originRef.current.value,
      destination: destinationRef.current.value,
    };
    sessionStorage.setItem('gmapInputValues', JSON.stringify(inputValues));

    // Call the parent component's submit function
    handleForm1Submit(e);
  };

  let minDistanceMessage;
  if (!isNextButtonEnabled) {
    if (selectedTripType === 'Oneway') {
      minDistanceMessage = <ErrorMessage>Minimum distance for Oneway trip: 130km</ErrorMessage>;
    } else if (selectedTripType === 'Twoway') {
      minDistanceMessage = <ErrorMessage>Minimum distance for Twoway trip: 250km</ErrorMessage>;
    }
  }

  return (
    <Container>
      <MapContainer>
        <GoogleMap
          center={center}
          zoom={9}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          onLoad={(map) => setMap(map)}
        >
          <Marker position={center} />
          {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
        </GoogleMap>
      </MapContainer>
      <FormContainer>
        <div>
          <Header>
            <h1>BOOK A <span>CAB</span></h1>
          </Header>
          <InnerForm>
            <div>
              <Autocomplete>
                <Input type='text' placeholder='Origin' ref={originRef} />
              </Autocomplete>
            </div>
            <div>
              <Autocomplete>
                <Input type='text' placeholder='Destination' ref={destinationRef} />
              </Autocomplete>
            </div>
            <Select value={selectedTripType} onChange={(e) => handleTripTypeSelect(e.target.value)}>
              <Option value='Oneway'>Oneway Trip</Option>
              <Option value='Twoway'>Twoway Trip</Option>
            </Select>
            <CarContainer>
              <CarBox onClick={() => handleCarSelect('Sedan')} isSelected={selectedCar === 'Sedan'}>
                <p>Sedan</p>
                <p>4 + 1 car</p>
              </CarBox>
              <CarBox onClick={() => handleCarSelect('XUV')} isSelected={selectedCar === 'XUV'}>
                <p>XUV</p>
                <p>7 + 1 cars</p>
              </CarBox>
              <CarBox onClick={() => handleCarSelect('Muv')} isSelected={selectedCar === 'Muv'}>
                <p>MUV</p>
                <p>7 + 1 cars</p>
              </CarBox>
            </CarContainer>
          </InnerForm>
        </div>
        <InnerForm>
          {directionsResponse && (
            <EstimationBox>
              <div className='distance'>
                <p>Distance: {distance}</p>
              </div>
              <div className='estimate'>
                <b>Estimate: ₹{price}</b>
              </div>
              <div className='summary'>
                <p>*[Base charges: ₹{price - betta} / Driver bata: ₹{betta}+ Excluded Toll at actuals]</p>
              </div>
            </EstimationBox>
          )}
          {minDistanceMessage}
          <ButtonGroup>
            <Button onClick={calculateRoute}>Estimate</Button>
            {isNextButtonEnabled && (
              <Button onClick={handleFormSubmit} disabled={!isNextButtonEnabled}>Next</Button>
            )
            }
          </ButtonGroup>
        </InnerForm>
      </FormContainer>
    </Container >
  );
}

export default Gmap;

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  padding: 40px 148px;
  display: flex;
  align-items: center;  
  gap: 10px;

  @media screen and (max-width: 768px) {
    height: 120vh;
    padding: 20px;
    flex-direction: column;
  }
`;

const MapContainer = styled.div`
  position: relative;
  width: 60%;
  height: 100%;
      @media screen and (max-width: 768px) {
        width: 95%;
        height: 400px;
    }
`;

const FormContainer = styled.div`
  position: relative;
  border: 1px solid #000;
  width: 500px;
  height: 100%;
    @media screen and (max-width: 768px) {
    width: 95%;
    height: 600px;
  }
`;

const InnerForm = styled.div`
  padding: 20px;
`;

const Input = styled.input`
padding: 12px;
border-bottom: 1px solid#000;
width: 100%;
`

const Select = styled.select`
padding: 12px;
border-bottom: 1px solid#000;
width: 100%;
margin-bottom: 5%;
`

const Option = styled.option`
padding: 12px;
border-bottom: 1px solid#000;
width: 100%;
border-radius: 0%;

`

const ErrorMessage = styled.div`
color: red;
text-align: center;
margin: 10px 0px;
`
const Button = styled.button`
padding: 8px 20px;
background-color: #000;
color: #fff;
`

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Header = styled.div`
padding: 20px;
background-color: #000;
  h1{
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    color: #fff;
  }
  span{
    color: #F0BF22;
  }
`;

const CarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const CarBox = styled.div`
  width: calc(50% - 20px);
  padding: 10px;
  border: 1px solid #ccc;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 4px;
  &:nth-child(2n) {
    margin-right: 0;
  }

  ${(props) =>
    props.isSelected &&
    `
      box-shadow: 0 0 10px #f0bf22;
      background-color: #F0BF22;
      color: #fff;
      
    `}
`;

const EstimationBox = styled.div`
text-align: center;
font-size: 12px;
.estimate{
  font-size: 24px;
}
.summary p{
  color: blue;
}
`