import React from 'react';
import styled from 'styled-components';
import backgroundImage2 from '../assets/background_2.png';
import { HighLightsData } from '../constant'

const HighLights = () => {
    return (
        <HighLightSection id='highlights'>
            <TextConatiner>
                <h1>Why book <span>with us?</span></h1>
            </TextConatiner>
            <CardConatiner>
                {
                    HighLightsData.map((element) => (
                        <Card key={element.id}>
                            <h1>{element.title}</h1>
                            <p>{element.description}</p>
                        </Card>
                    ))
                }
            </CardConatiner>
        </HighLightSection>
    );
}

export default HighLights;

const HighLightSection = styled.div`
    position: relative;
    width: 100vw;
    min-height: 80vh;
    height: 100%;
    padding: 40px 148px;
    background-image: url(${backgroundImage2});
    background-position: center;
    background-size: cover;
    @media screen and (max-width: 768px) {
        padding: 20px;
}
`;

const TextConatiner = styled.div`
text-align: center;
h1{
    text-transform: uppercase;
    color: #fff;
    font-size: 28px;
    font-weight: 600;
    span{
        color: #f0bf22;
    }
}
`

const CardConatiner = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
flex-wrap: wrap;
height: 100%;
`

const Card = styled.div`
width: 500px;
margin: 20px 0px;

h1{
    color: #f0bf22;
}

p{
    color: #fff;
}

@media screen and (max-width: 768px) {
p{
    text-align: justify;
}
}
`
