import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TermsAndConditions from "./components/TermsAndConditions";
import Home from "./pages/Home";
import UnderConstruction from "./assets/underconstruction.png";
import styled from 'styled-components';

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Dark background overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the overlay is on top */
`;

const PopupContent = styled.div`
  max-width: 80%;
  max-height: 80%;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

function App() {
  const [showPopup, setShowPopup] = useState(true); // Show the popup initially

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<TermsAndConditions />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
