// src/TermsAndConditions.js
import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f4f4;
  padding: 20px;
`;

const ContentContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  text-align: left;

  @media (max-width: 600px) {
    padding: 15px;
  }
`;

const Title = styled.h1`
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #333;
`;

const Text = styled.p`
  font-size: 1em;
  line-height: 1.6;
  color: #666;
  margin-bottom: 20px;
`;

const TermsAndConditions = () => {
    return (
        <PageContainer>
            <ContentContainer>
                <Title>Terms and Conditions</Title>
                <Text>
                    Welcome to our taxi booking service. Before you proceed with your booking,
                    please take a moment to read our terms and conditions.
                </Text>
                <Text>
                    [Terms and conditions content goes here...]
                </Text>
            </ContentContainer>
        </PageContainer>
    );
};

export default TermsAndConditions;
