import React from 'react'
import styled from 'styled-components'

const Call = () => {
    return (
        <CallSection id='contact'>
            <h2>WE ARE READY TO TAKE YOUR CALL 24 HOURS, 7 DAYS!</h2>
            <b><h1>+91 9362 25 7000</h1></b>
        </CallSection>
    )
}

export default Call

const CallSection = styled.div`
width: 100vw;
height: 30vh;
background-color: #f0bf22;
color: #000;
text-align: center;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

h1{
    font-size: 62px;
}
@media screen and (max-width: 768px) {
        h1{
            font-size: 42px;
        }
        h2{
            font-size: 18px;
        }
}
`