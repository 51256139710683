import React from 'react'
import styled from 'styled-components'
import backgroudImage3 from '../assets/background_3.png'
import { TestimonialData } from '../constant'

const Testimonial = () => {
    return (
        <TestimonialSection>
            <TextConatiner>
                <h1>Happy <span>Clients</span></h1>
            </TextConatiner>
            <CardConatiner>
                {
                    TestimonialData.map((element) => (
                        <Card key={element.id}>
                            <h1>{element.title}</h1>
                            <p><span>"</span>{element.descripiton}<span>"</span></p>
                        </Card>
                    ))
                }
            </CardConatiner>
        </TestimonialSection>
    )
}

export default Testimonial

const TestimonialSection = styled.div`
position: relative;
width: 100vw;
min-height: 70vh;
height: 100%;
padding: 40px 148px;
background-image: url(${backgroudImage3});
background-position: center;
background-size: cover;
    @media screen and (max-width: 768px) {
        padding: 20px;
}
`

const TextConatiner = styled.div`
text-align: center;
h1{
    text-transform: uppercase;
    color: #fff;
    font-size: 28px;
    font-weight: 600;
    span{
        color: #f0bf22;
    }
}
`

const CardConatiner = styled.div`
display: flex;
flex-wrap: wrap;
align-items: center;
justify-content: space-between;
flex-wrap: wrap;
height: 100%;
`

const Card = styled.div`
width: 500px;
margin: 20px 0px;
background-color: #000;
padding: 20px 50px;
border-radius: 10px;

h1{
    color: #f0bf22;
}

p{
    color: #fff;
}
span{
    font-size: 24px;
}
`
