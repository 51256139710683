import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Form2 = ({
    name,
    setName,
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    pickDate,
    setPickDate,
    pickTime,
    setPickTime,
    dropDate,
    setDropDate,
    dropTime,
    setDropTime,
    adultCount,
    setAdultCount,
    childCount,
    setChildCount,
    luggageCount,
    setLuggageCount,
    pickUpAddress,
    setPickUpAddress,
    dropAddress,
    setDropAddress,
    termsAccepted,
    setTermsAccepted,
    handlePrevious2,
    handleForm2Submit
}) => {
    const isFormValid = () => {
        return (
            name !== '' &&
            email !== '' &&
            phoneNumber !== '' &&
            pickDate !== '' &&
            pickTime !== '' &&
            pickUpAddress !== '' &&
            dropAddress !== '' &&
            termsAccepted
        );
    };
    const handleNextButtonClick = (e) => {
        if (!isFormValid()) {
            alert("Please fill in all the required fields.");
        } else {
            handleForm2Submit(e);
        }
    };

    const handleLinkClick = (event) => {
        event.preventDefault();
        window.open('/terms', '_blank', 'noopener,noreferrer');
    };

    return (
        <Container>
            <FormContainer>
                <Header>
                    <h1>BOOK A <span>CAB</span></h1>
                </Header>
                <InnerForm>
                    <Input
                        type="text"
                        placeholder="Name*"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <Input
                        type="tel"
                        placeholder="Phone Number*"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        required
                    />
                    <Input
                        type="email"
                        placeholder="Email*"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <Flex>
                        <div>
                            <Label htmlFor="pickDate">Pick Date</Label>
                            <Input
                                id="pickDate"
                                type="date"
                                placeholder="Pick Date*"
                                value={pickDate}
                                onChange={(e) => setPickDate(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <Label htmlFor="pickTime">Pick Time</Label>
                            <Input
                                id="pickTime"
                                type="time"
                                placeholder="Pick up time*"
                                value={pickTime}
                                onChange={(e) => setPickTime(e.target.value)}
                                required
                            />
                        </div>
                    </Flex>
                    <Flex>
                        <div>
                            <Label htmlFor="dropDate">Drop Date</Label>
                            <Input
                                id="dropDate"
                                type="date"
                                placeholder="Drop Date"
                                value={dropDate}
                                onChange={(e) => setDropDate(e.target.value)}
                            />
                        </div>
                        <div>
                            <Label htmlFor="dropTime">Drop Time</Label>
                            <Input
                                id="dropTime"
                                type="time"
                                placeholder="Drop Time"
                                value={dropTime}
                                onChange={(e) => setDropTime(e.target.value)}
                            />
                        </div>
                    </Flex>
                    <Flex>
                        <div>
                            <Label htmlFor="adultCount">No of Adults*</Label>
                            <Select
                                id="adultCount"
                                value={adultCount}
                                onChange={(e) => setAdultCount(e.target.value)}
                                required
                            >
                                {[0, 1, 2, 3, 4, 5, 6, 7].map((optionValue) => (
                                    <option key={optionValue} value={optionValue}>
                                        {optionValue}
                                    </option>
                                ))}
                            </Select>
                        </div>
                        <div>
                            <Label htmlFor="childCount">No of Children*</Label>
                            <Select
                                id="childCount"
                                value={childCount}
                                onChange={(e) => setChildCount(e.target.value)}
                                required
                            >
                                {[0, 1, 2, 3, 4, 5, 6, 7].map(option => (
                                    <option key={option} value={option}>{option}</option>
                                ))}
                            </Select>
                        </div>
                        <div>
                            <Label htmlFor="luggageCount">No of Luggage*</Label>
                            <Select
                                id="luggageCount"
                                value={luggageCount}
                                onChange={(e) => setLuggageCount(e.target.value)}
                                required
                            >
                                {[0, 1, 2, 3, 4, 5, 6, 7].map(option => (
                                    <option key={option} value={option}>{option}</option>
                                ))}
                            </Select>
                        </div>
                    </Flex>
                    <Input
                        type="text"
                        placeholder="Pick up address*"
                        value={pickUpAddress}
                        onChange={(e) => setPickUpAddress(e.target.value)}
                        required
                    />
                    <Input
                        type="text"
                        placeholder="Drop address*"
                        value={dropAddress}
                        onChange={(e) => setDropAddress(e.target.value)}
                        required
                    />
                    <div className='terms'>
                        <Checkbox
                            className='checkbox'
                            type='checkbox'
                            label="I accept the Terms and Conditions."
                            checked={termsAccepted}
                            onChange={(e) => setTermsAccepted(e.target.checked)}
                            required
                        />
                        <Link to="/terms" onClick={handleLinkClick}>
                            <Label>
                                I accept the Terms and Conditions
                            </Label>
                        </Link>
                    </div>
                    <ButtonGroup>
                        <Button onClick={handlePrevious2}>Previous</Button>
                        <Button onClick={handleNextButtonClick}>Next</Button>
                    </ButtonGroup>
                </InnerForm>
            </FormContainer>
        </Container>
    );
};

export default Form2;

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    padding: 20px;
  }
`;

const Header = styled.div`
  padding: 20px;
  background-color: #000;
  h1{
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    color: #fff;
  }
  span{
    color: #F0BF22;
  }
`;

const FormContainer = styled.div`
  position: relative;
  border: 1px solid #000;
  width: 500px;
  margin: 20px 0px;
`;

const InnerForm = styled.div`
  padding: 20px;
`;

const Input = styled.input`
  margin-bottom: 10px;
  padding: 8px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #000;
`;

const Select = styled.select`
  margin-bottom: 10px;
  padding: 8px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #000;
`;

const Button = styled.button`
  padding: 8px 20px;
  background-color: #000;
  color: #fff;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Checkbox = styled.input`
  margin-right: 10px; 
`;

const Label = styled.label`
  display: inline-block;
  margin-bottom: 10px;
`;

const Flex = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
`;
