import React from 'react';
import styled from 'styled-components';
import SedanImage from "../assets/sedan.png";
import XUVImage from "../assets/XUV.png";
import MUVImage from "../assets/MUV.png";

const Tariffs = () => {
    return (
        <TariffSection id='tariff' aria-label="Tariffs Section">
            <div className='header'>
                <h1 className='span'>OUR</h1>
                <h1>TARIFFS</h1>
            </div>
            <CardContainer>
                <Card>
                    <img src={SedanImage} alt="Sedan car" />
                    <h2>Sedan 4 + 1 Car</h2>
                    <p>
                        <strong>One-way Trip:</strong> Minimum starting 130 km, ₹14 per km of distance + driver Betta ₹300<br />
                        <strong>Two-way Trip:</strong> Minimum starting 250 km, ₹13 per km of distance + driver Betta ₹300
                    </p>
                </Card>
                <Card className='yellow'>
                    <img src={XUVImage} alt="XUV car" />
                    <h2>XUV 7 + 1 Car</h2>
                    <p>
                        <strong>One-way Trip:</strong> Minimum starting 130 km, ₹18 per km of distance + driver Betta ₹300<br />
                        <strong>Two-way Trip:</strong> Minimum starting 250 km, ₹17 per km of distance + driver Betta ₹300
                    </p>
                </Card>
                <Card>
                    <img src={MUVImage} alt="MUV car" />
                    <h2>MUV 7 + 1 Car</h2>
                    <p>
                        <strong>One-way Trip:</strong> Minimum starting 130 km, ₹19 per km of distance + driver Betta 300<br />
                        <strong>Two-way Trip:</strong> Minimum starting 250 km, ₹18 per km of distance + driver Betta 300
                    </p>
                </Card>
            </CardContainer>
        </TariffSection>
    );
}

export default Tariffs;

const TariffSection = styled.div`
    height: 100%;
    padding: 40px 148px;
    color: #000;

    .header h1 {
        font-size: 28px;
        line-height: 28px;
        font-weight: 600;
        text-align: center;
    }

    .span {
        color: #F0BF22;
    }

    @media screen and (max-width: 768px) {
        padding: 20px;
    }
`;

const CardContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 2%;
    .yellow {
        background-color: #F0BF22 !important;
    }

    @media screen and (max-width: 768px) {
        justify-content: center;
    }
`;

const Card = styled.div`
    width: 310px;
    height: 350px;
    background-color: #F5F4F2;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;

    @media screen and (max-width: 768px) {
        margin-bottom: 10px;
    }

    img {
        width: 250px;
    }

    h1 {
        font-weight: 600;
    }

    p {
        font-size: 14px;
    }
`;
