import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import styled from 'styled-components';
import Client from "../assets/clients.png"
import Cities from "../assets/cities.png"
import KM from "../assets/kilometers.png"

const Countup = () => {
    const [activateCountUp, setActivateCountUp] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const section = document.querySelector('#countup-section');
            const scrollPosition = window.innerHeight + window.scrollY;
            const sectionPosition = section.offsetTop + section.offsetHeight / 2;

            if (scrollPosition >= sectionPosition) {
                setActivateCountUp(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <CountUpWrapper id="countup-section">
            <Left>
                <Card>
                    <img src={Client} alt="clients" />
                    <div>
                        <b><StyledCountUp end={activateCountUp ? 500 : 0} duration={5} className='title' />+</b><br />
                        <b>Happy Clients</b>
                        <p className='description'>Hundreds of satisfied clients</p>
                    </div>
                </Card>
                <Card>
                    <img src={Cities} alt="cities" />
                    <div>
                        <b><StyledCountUp end={activateCountUp ? 250 : 0} duration={5} className='title' />+</b><br />
                        <b>Cities covered</b>
                        <p className='description'>Seamless travel across multiple cities</p>
                    </div>
                </Card>
                <Card>
                    <img src={KM} alt="kilometer" />
                    <div>
                        <b><StyledCountUp end={activateCountUp ? 1500 : 0} duration={5} className='title' />+</b><br />
                        <b>Km of Journey</b>
                        <p className='description'>Thousands of Km of memorable journeys</p>
                    </div>
                </Card>
            </Left>

        </CountUpWrapper>
    );
};

export default Countup;

const CountUpWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
    width: 100%;
    height: 100%;
    padding: 20px 148px;
    background-color: #F0BF22;
    

    @media screen and (max-width: 768px) {
        padding: 20px;
        flex-direction: column;
    }
`;

const Box = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    max-width: 800px;
    height: 150px;
    margin: auto;
    padding: 25px;
    background-color: #000;
    
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        padding: 10px;
    }
`;

const CountUpBox = styled.div`
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
    font-size: 16px;
    color: #fff;
    


    .text {
        text-align: center;
    }

    @media screen and (max-width: 768px) {
        width: 50%;
        font-size: 16px;
        margin: 10px auto;
    }
`;

const Left = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    @media screen and (max-width: 768px) {
     flex-direction  :column ;
    }

   
`;

const StyledCountUp = styled(CountUp)`
        color: #000000;
        margin: 10px 0;
        letter-spacing: -1px;
        font-size: 22px;
        font-weight: 600;
`;


const Card = styled.div`
width: 320px;
height: 300px;
display: flex;
flex-direction: column;
align-items: center;
gap: 5px;
text-align: center;
 @media screen and (max-width: 768px) {
        flex-direction: row;
        text-align: left;
        gap: 0px;
    }
    img{
        width: 140px;
        height: 140px;
        /* background-image: linear-gradient(to bottom, #f0bf22, #f0bf22, #f0bf22, #76BED0); */
    }

    p{
        font-size: 22px;
    }

    .description{
        font-weight: 500;
        font-size: 16px;
        color: #fff;
        text-align: center;
        opacity: 0.8;
         @media screen and (max-width: 768px) {
        text-align: left;
     }
    }

    

`;

