import React, { useState } from 'react';
import styled from 'styled-components';
import About from '../components/About';
import Call from '../components/Call';
import CountUp from '../components/Countup';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import HighLights from '../components/HighLights';
import Tariffs from '../components/Tariffs';
import TaxiBookingForm from '../components/TaxiBookingForm';
import Testimonial from '../components/Testimonial';
import OfferCard from '../components/OfferCard';

const Home = () => {
    const [showOffer, setShowOffer] = useState(false);

    const handleCloseOffer = () => {
        setShowOffer(false);
    };

    return (
        <Section>
            <Hero />
            <About />
            <Tariffs />
            <CountUp />
            <HighLights />
            {showOffer && (
                <>
                    <Overlay onClick={handleCloseOffer} />
                    <OfferPopup>
                        <OfferContent>
                            <OfferText>
                                Get 200rs off for online booking! <CloseButton onClick={handleCloseOffer}>X</CloseButton>
                            </OfferText>
                        </OfferContent>
                    </OfferPopup>
                </>
            )}
            <OfferCard />
            <TaxiBookingForm />
            <Testimonial />
            <Call />
            <Footer />
        </Section>
    );
};

export default Home;

const Section = styled.section`
  height: 100%;
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 9998; /* Ensure the overlay is below the pop-up */
`;

const OfferPopup = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 25px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 9999;
`;

const OfferContent = styled.div`
    text-align: center;
`;

const OfferText = styled.p`
    font-size: 28px;
    font-weight: 600;
       animation: Color 4s linear infinite;
  -webkit-animation: Color 4s ease-in-out infinite;


@keyframes Color{
  0%{
    color:#A0D468;
  }
  
  20%{
    color:#4FC1E9;
  }
  
  40%{
    color:#FFCE54;
  }
  
  60%{
    color:#FC6E51;
  }
  
  80%{
    color:#ED5565;
  }
  
  100%{
    color:#AC92EC;
  }
}

@-moz-keyframes Color{
  0%{
    color:#A0D468;
  }
  
  20%{
    color:#4FC1E9;
  }
  
  40%{
    color:#FFCE54;
  }
  
  60%{
    color:#FC6E51;
  }
  
  80%{
    color:#ED5565;
  }
  
  100%{
    color:#AC92EC;
  }
}

@-webkit-keyframes Color{
  0%{
    color:#A0D468;
  }
  
  20%{
    color:#4FC1E9;
  }
  
  40%{
    color:#FFCE54;
  }
  
  60%{
    color:#FC6E51;
  }
  
  80%{
    color:#ED5565;
  }
  
  100%{
    color:#AC92EC;
  }
}
`;

const CloseButton = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    position: absolute;
    top: 5px;
    right: 10px;
    color: #888;
`;
