import React from 'react';
import { Navbar } from 'responsive-navbar-react';
import 'responsive-navbar-react/dist/index.css';
import LogoImage from "../assets/Logo.png";
import styled from 'styled-components';

const MyNavbar = () => {
  const props = {
    items: [
      {
        text: 'Home',
        link: '/'
      },
      {
        text: 'About',
        link: '#about'
      },
      {
        text: 'Tariff',
        link: '#tariff'
      },
      {
        text: 'Book Now',
        link: '#bookNow'
      },
      {
        text: 'Contact',
        link: '#contact'
      }
    ],
    logo: {
      image: LogoImage,
      alt: 'Responsive Navbar React',
      style: {
        width: '150px',
        height: 'auto', // Ensure the aspect ratio is maintained
        backgroundColor: 'red',
        padding: '10px',
        display: 'flex',
        alignItems: 'center', // Center the logo vertically
        justifyContent: 'center', // Center the logo horizontally
      }
    },
    style: {
      barStyles: {
        background: 'transparent',
        zIndex: 9999,
        boxShadow: 'none',
      },
      sidebarStyles: {
        background: '#F0BF22',
        boxShadow: 'none',
        border: 'none',
        buttonColor: 'white'
      }
    }
  };

  return (
    <>
   <Container>
        <Logo src={LogoImage} alt="" srcset="" />
        <Navbar {...props} />
   </Container>
    </>
  );
};

export default MyNavbar;

const Container = styled.div`
display: flex;
align-items: center;
`

const Logo = styled.img`
width: 80px;
`