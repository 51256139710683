import React, { useState } from 'react';
import styled from 'styled-components';
import QRImage from "../../assets/PhonePayQR.jpg"

const Form3 = ({ handlePrevious3, handleForm3Submit, transactionId, setTransactionId }) => {
  const [isValid, setIsValid] = useState(true);

  const validateTransactionId = (id) => {
    // Example regex for PhonePe and UPI transaction ID validation
    const regex = /^[a-zA-Z0-9]{12,}$/;
    return regex.test(id);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setTransactionId(value);
    setIsValid(validateTransactionId(value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid) {
      handleForm3Submit(e);
    } else {
      alert('Please enter a valid transaction ID');
    }
  };
    return (
        <>
            <Container>
                <div>
                    <h1>Make a payment of 300Rs in advance to book the taxi.</h1>
                    <Image src={QRImage} alt="Transaction Image" />
                    <Form onSubmit={handleSubmit}>
                        <Input
                            type="text"
                            placeholder="Enter Transaction ID"
                            value={transactionId}
                            onChange={handleChange}
                        />
                        {!isValid && <ErrorText>Invalid transaction ID</ErrorText>}
                        <ButtonGroup>
                            <Button type="button" onClick={handlePrevious3}>Previous</Button>
                            <Button type="submit">Next</Button>
                        </ButtonGroup>
                    </Form>
                </div>
            </Container>
        </>
    );
};

export default Form3;

const Container = styled.div`
  position: relative;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    padding: 20px;
  }

  div{
    text-align: center;
  }
`;

const Image = styled.img`
  width: 300px;
  height: auto;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const ErrorText = styled.p`
  color: red;
  margin-bottom: 10px;
`;

const Button = styled.button`
  padding: 8px 20px;
  background-color: #000;
  color: #fff;
  border: none; 
  cursor: pointer;

  &:hover {
    background-color: #333;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
`;
